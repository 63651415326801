// chains/chains.ts
import {
  mainnet as mainnet_,
  bsc as bsc_,
  polygon as polygon_
} from "wagmi/chains";
var polygonTest = {
  id: 80001,
  name: "Polygon Testnet",
  network: "mumbai",
  rpcUrls: {
    public: {
      http: ["https://polygon-testnet.public.blastapi.io"]
    },
    default: {
      http: ["https://polygon-testnet.public.blastapi.io"]
    }
  },
  contracts: {
    multicall3: {
      address: "0xEE70ffeb98002206161D91889804EC7294130b07",
      blockCreated: 36511935
    }
  },
  nativeCurrency: {
    name: "Polygon",
    symbol: "MATIC",
    decimals: 18
  },
  blockExplorers: {
    default: { name: "PolygonScan", url: "https://mumbai.polygonscan.com/" }
  },
  testnet: true
};
var polygon = {
  ...polygon_,
  rpcUrls: {
    ...polygon_.rpcUrls,
    public: {
      ...polygon_.rpcUrls.public,
      http: ["https://polygon-rpc.com"]
    },
    default: {
      ...polygon_.rpcUrls.default,
      http: ["https://polygon-rpc.com"]
    }
  },
  contracts: {
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 36909610
    }
  }
};
var bsc = {
  ...bsc_,
  rpcUrls: {
    ...bsc_.rpcUrls,
    public: {
      ...bsc_.rpcUrls.public,
      http: ["https://bsc-dataseed.binance.org"]
    },
    default: {
      ...bsc_.rpcUrls.default,
      http: ["https://bsc-dataseed.binance.org"]
    }
  },
  contracts: {
    multicall3: {
      address: "0x816Cc673751857bFBbB9e670f27c0241F58146C0",
      blockCreated: 29240627
    }
  }
};
var bscTest = {
  id: 97,
  name: "BSC Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Chain Native Token",
    symbol: "tBNB"
  },
  rpcUrls: {
    public: { http: ["https://data-seed-prebsc-1-s3.binance.org:8545"] },
    default: { http: ["https://data-seed-prebsc-1-s3.binance.org:8545"] }
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" }
  },
  contracts: {
    multicall3: {
      address: "0x305eEE7Dc8cB2A305b138B779b40302E173c1cfa",
      blockCreated: 17422483
    }
  },
  testnet: true
};
var bahamut = {
  id: 5165,
  name: "Bahamut",
  network: "bahamut",
  nativeCurrency: {
    decimals: 18,
    name: "Native Token",
    symbol: "FTN"
  },
  rpcUrls: {
    public: { http: ["https://bahamut-rpc.publicnode.com"] },
    default: { http: ["https://bahamut-rpc.publicnode.com"] }
  },
  blockExplorers: {
    default: { name: "FTNscan", url: "https://www.ftnscan.com" }
  },
  contracts: {
    multicall3: {
      address: "0x1AA0663FBA06B1D5482E7CaA675aa12fB04F4D0f",
      blockCreated: 17422483
    }
  },
  testnet: false
};
var mainnet = {
  ...mainnet_,
  contracts: {
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 23755020
    }
  }
};
var CHAINS = [bahamut];
export {
  CHAINS,
  bahamut,
  bsc,
  bscTest,
  mainnet,
  polygon,
  polygonTest
};
