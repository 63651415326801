import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { Box, Flex } from "../../../../components/Box";
import {
  ChevronDownGreyIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LogoutIcon,
  WarningIcon,
} from "../../../../components/Svg";
import { UserMenuProps, variants } from "./types";
import MenuIcon from "./MenuIcon";
import { UserMenuItem } from "./styles";
import { useTranslation } from "@pancakeswap/localization";
import { useMatchBreakpoints } from "../../../../contexts";

export const StyledUserMenu = styled(Flex)<{ isInHeader: any; isWrongNetwork: any }>`
  /* max-width: 230px; */
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isInHeader }) => isInHeader && "transparent"};
  border-radius: 12px;
  /* box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  display: flex;
  /* padding: ${({ isWrongNetwork }) => (isWrongNetwork ? "13px 10px" : "13px 25px")}; */
  padding: ${({ isInHeader }) => (isInHeader ? "8px 32px" : "10px 18.5px")};
  border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  position: relative;
  gap: 8px;
  width: 100%;
  height: 40px;
  /* margin-top: 16px; */
  @media (max-width: 967px) {
    ${({ isInHeader }) => isInHeader} {
      border: none;
      box-shadow: none;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 30px;
      padding: 6px;
    }
    padding: 8px 16px;
  }
  &:hover {
    opacity: 0.65;
  }
`;

export const LabelText = styled.div<{ isInHeader: boolean | undefined }>`
  color: ${({ theme }) => theme.colors.text};
  white-space: nowrap;
  display: ${({ isInHeader }) => !isInHeader && "none"};
  /* width: 128px; */
  font-weight: 600;
  font-size: 12px;
  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  position: absolute;
  top: 100%;
  left: -240%;
  ${({ theme }) => theme.mediaQueries.lg} {
    left: 0;
  }
  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const Content = styled.div`
  width: 209px;
  position: fixed;
  z-index: 2000;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 16px;
  padding-top: 12px;
  border: 1px solid ${({ theme }) => theme.colors.backgroundGrey};
`;

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  avatarClassName,
  variant = variants.DEFAULT,
  children,
  disabled,
  placement = "bottom-end",
  recalculatePopover,
  logout,
  isInHeader,
  isWrongNetwork,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const accountEllipsis = account ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}` : null;
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { styles, attributes, update } = usePopper(targetRef, tooltipRef, {
    strategy: "absolute",
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });
  // recalculate the popover position
  useEffect(() => {
    if (recalculatePopover && isOpen && update) update();
  }, [isOpen, update, recalculatePopover]);

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };
    // console.log(avatarSrc);

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex position="relative" justifyContent="center" alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        isWrongNetwork={isWrongNetwork}
        isInHeader={isInHeader}
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
      >
        {/* <AddressWallet isInHeader={isInHeader}>{accountEllipsis}</AddressWallet> */}
        {!isInHeader && <MenuIcon avatarSrc={avatarSrc} variant={variant} />}
        <LabelText isInHeader={isInHeader} title={typeof text === "string" ? text || account : account}>
          {accountEllipsis || text}
        </LabelText>
        {!disabled && !isInHeader && isDesktop && <ChevronDownGreyIcon />}
      </StyledUserMenu>
      {!disabled && isInHeader && <LogoutIcon onClick={logout} marginLeft="16px" />}
      {!disabled && !isInHeader && (
        <Menu
          style={{ paddingTop: "13px", background: "transparent" }}
          ref={setTooltipRef}
          {...attributes.popper}
          isOpen={isOpen}
        >
          <Content onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Content>
        </Menu>
      )}
    </Flex>
  );
};

export default UserMenu;
