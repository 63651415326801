import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { ENV_IS_PROD } from './env'

export const BSC_PROD_NODE =
  process.env.NEXT_PUBLIC_NODE_PRODUCTION || ENV_IS_PROD
    ? 'https://bsc-dataseed1.binance.org/'
    : 'https://polygon-testnet.public.blastapi.io'
// export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc-dataseed1.binance.org/'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

export default null
