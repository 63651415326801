import { ChainId } from '@pancakeswap/sdk'
// import { Pool } from '@pancakeswap/uikit'
import addresses from 'config/constants/contracts'
import { BUSD as b } from '@pancakeswap/tokens'
// import { VaultKey } from 'state/types'

export const getAddress = (address: any, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.BSC]
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}

export const getZapAddress = () => {
  return getAddress(addresses.zap)
}
export const getICakeAddress = () => {
  return getAddress(addresses.iCake)
}