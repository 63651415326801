export const ENV_IS_PROD = process.env.NEXT_PUBLIC_PROD === 'true'

console.log('env NEXT_PUBLIC_PROD', process.env.NEXT_PUBLIC_PROD)
export const chainId = ENV_IS_PROD ? 5165 : 97

export const chartUrl = ENV_IS_PROD
  ? 'https://bahamutdexapi.kujata.io'
  : 'https://gotbit-bahamut-dex-api-backend-dev.dev.gotbit.app'
export const NXDT_COIN = ENV_IS_PROD
  ? '0x803a6f46a6d1ca52Ce3bD84743309cDD8313004f'
  : '0xF175Bb19b01D096D2F4c32DE8C6155665ca2fA77'
