import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.9333 13.3333H6.4C6.29391 13.3333 6.19217 13.2912 6.11716 13.2162C6.04214 13.1412 6 13.0394 6 12.9333V6.4C6 6.29391 6.04214 6.19217 6.11716 6.11716C6.19217 6.04214 6.29391 6 6.4 6H12.9333C13.0394 6 13.1412 6.04214 13.2162 6.11716C13.2912 6.19217 13.3333 6.29391 13.3333 6.4V12.9333C13.3333 13.0394 13.2912 13.1412 13.2162 13.2162C13.1412 13.2912 13.0394 13.3333 12.9333 13.3333Z"
        stroke="#AABBC7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0013 5.99984V3.0665C10.0013 2.96042 9.95916 2.85868 9.88414 2.78366C9.80913 2.70865 9.70739 2.6665 9.6013 2.6665H3.06797C2.96188 2.6665 2.86014 2.70865 2.78513 2.78366C2.71011 2.85868 2.66797 2.96042 2.66797 3.0665V9.59984C2.66797 9.70592 2.71011 9.80767 2.78513 9.88268C2.86014 9.95769 2.96188 9.99984 3.06797 9.99984H6.0013"
        stroke="#AABBC7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Icon;
