import { CHAINS, mainnet, bsc } from '@pancakeswap/wagmi/chains'
import { configureChains, createClient } from 'wagmi'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { SafeConnector } from '@gnosis.pm/safe-apps-wagmi'
import { ChainId } from '@pancakeswap/sdk'
import { avalancheFuji } from 'wagmi/chains'

const getNodeRealUrl = (networkName: string) => {
  let host = null

  switch (networkName) {
    case 'homestead':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_ETH) {
        host = `eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`
      }
      break
    case 'rinkeby':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_RINKEBY) {
        host = `eth-rinkeby.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_RINKEBY}`
      }
      break
    case 'goerli':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI) {
        host = `eth-goerli.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`
      }
      break
    default:
      host = null
  }

  if (!host) {
    return null
  }

  const url = `https://${host}`
  return {
    http: url,
    webSocket: url.replace(/^http/i, 'wss').replace('.nodereal.io/v1', '.nodereal.io/ws/v1'),
  }
}

export const PUBLIC_NODES = {
  [ChainId.BSC]: [
    // process.env.NEXT_PUBLIC_PROD,
    // process.env.NEXT_PUBLIC_NODE_REAL_API_ETH,
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.binance.org',
  ].filter(Boolean),
  [ChainId.BSC_TESTNET]: ['https://bsc-testnet-rpc.publicnode.com'],
  [ChainId.ETHEREUM]: [
    // process.env.NEXT_PUBLIC_PROD,
    // process.env.NEXT_PUBLIC_NODE_REAL_API_ETH,
    'https://eth.llamarpc.com',
    'https://cloudflare-eth.com',
  ].filter(Boolean),
  [ChainId.POLYGON]: [
    // process.env.NEXT_PUBLIC_PROD,
    // process.env.NEXT_PUBLIC_NODE_REAL_API_ETH,
    'https://polygon-rpc.com',
    'https://polygon-rpc.com',
  ], //
  [ChainId.POLYGON_TESTNET]: ['https://polygon-testnet.public.blastapi.io'],
  [ChainId.FUJI]: [...avalancheFuji.rpcUrls.public.http, 'https://rpc.ankr.com/avalanche_fuji'],
  [ChainId.BAHAMUT]: ['https://bahamut-rpc.publicnode.com', 'https://rpc1.bahamut.io', 'https://rpc2.bahamut.io	'],
} as Record<ChainId, readonly string[]>

export const { provider, chains } = configureChains(
  CHAINS,
  Array.from({ length: 6 })
    .map((_, i) => i)
    .map((i) => {
      return jsonRpcProvider({
        rpc: (chain) => {
          if (!!process.env.NEXT_PUBLIC_NODE_PRODUCTION && chain.id === bsc.id) {
            return { http: process.env.NEXT_PUBLIC_NODE_PRODUCTION } //testnet/mainnet
          }
          if (process.env.NODE_ENV === 'test' && chain.id === mainnet.id) {
            return { http: 'https://cloudflare-eth.com' }
          }
          return PUBLIC_NODES[chain.id]?.[i]
            ? {
                http: PUBLIC_NODES[chain.id][i],
              }
            : null
        },
      })
    }),
)

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'PancakeSwap',
    appLogoUrl: 'https://pancakeswap.com/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    showQrModal: true,
    projectId: '05e20689444fa076d8b24d6c35574702',
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '9999999',
      },
    },
  },
})

export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
  chains,
  options: {
    showQrModal: false,
    projectId: '05e20689444fa076d8b24d6c35574702',
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '9999999',
      },
    },
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

// const bloctoConnector = new BloctoConnector({
//   chains,
//   options: {
//     defaultChainId: 56,
//     appId: 'e2f2f0cd-3ceb-4dec-b293-bb555f2ed5af',
//   },
// })

// export const bscConnector = new BinanceWalletConnector({ chains })

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [
    // new SafeConnector({ chains }),
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    // bscConnector,
    // bloctoConnector,
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => {
  const found = chains.find((c) => c.id === chainId)
  return found ? 'testnet' in found : false
})
