import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg {...props} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4154 5.99995C16.4155 7.06957 16.099 8.1153 15.5058 9.00537C14.9126 9.89543 14.0693 10.59 13.082 11.0016V11C13.082 9.12134 12.3358 7.31966 11.0074 5.99128C9.67899 4.6629 7.87731 3.91662 5.9987 3.91662H5.99703C6.47937 2.7614 7.34685 1.80884 8.45204 1.22084C9.55722 0.63283 10.8319 0.445659 12.0595 0.691134C13.2871 0.936608 14.3918 1.59958 15.1858 2.56738C15.9799 3.53518 16.4144 4.74808 16.4154 5.99995ZM4.33203 0.999954C3.44798 0.999954 2.60013 1.35114 1.97501 1.97626C1.34989 2.60139 0.998698 3.44923 0.998698 4.33329V5.58329H2.66536V4.33329C2.66536 3.89126 2.84096 3.46734 3.15352 3.15478C3.46608 2.84222 3.89 2.66662 4.33203 2.66662H5.58203V0.999954H4.33203ZM14.332 11.4166V12.6666C14.332 13.1086 14.1564 13.5326 13.8439 13.8451C13.5313 14.1577 13.1074 14.3333 12.6654 14.3333H11.4154V16H12.6654C13.5494 16 14.3973 15.6488 15.0224 15.0236C15.6475 14.3985 15.9987 13.5507 15.9987 12.6666V11.4166H14.332ZM5.9987 16.4166C7.43529 16.4166 8.81304 15.8459 9.82886 14.8301C10.8447 13.8143 11.4154 12.4365 11.4154 11C11.4154 9.56336 10.8447 8.18561 9.82886 7.16979C8.81304 6.15397 7.43529 5.58329 5.9987 5.58329C4.56211 5.58329 3.18436 6.15397 2.16854 7.16979C1.15271 8.18561 0.582031 9.56336 0.582031 11C0.582031 12.4365 1.15271 13.8143 2.16854 14.8301C3.18436 15.8459 4.56211 16.4166 5.9987 16.4166ZM5.9987 8.91662L8.08203 11L5.9987 13.0833L3.91536 11L5.9987 8.91662Z" />
    </Svg>
  );
};

export default Icon;
