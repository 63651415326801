import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="27" stroke="#5B65F0" stroke-width="3" />
      <path d="M49.8077 34.5L35.3077 48.9998L30 43.6052" stroke="#5B65F0" stroke-width="3" stroke-linecap="square" />
    </svg>
  );
};

export default Icon;
