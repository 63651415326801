import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M18.2378 6.13732C17.0906 5.61093 15.8603 5.22308 14.574 5.00093C14.5506 4.99664 14.5271 5.00737 14.5151 5.02878C14.3568 5.3102 14.1816 5.6773 14.0589 5.96587C12.6754 5.75873 11.2989 5.75873 9.94378 5.96587C9.82101 5.67091 9.6394 5.3102 9.48046 5.02878C9.4684 5.00807 9.44498 4.99735 9.42157 5.00093C8.13597 5.22235 6.90571 5.61019 5.75772 6.13732C5.74777 6.14161 5.73926 6.14875 5.7336 6.15803C3.40007 9.6443 2.76079 13.0449 3.07438 16.4033C3.07579 16.4197 3.08503 16.4354 3.0978 16.4454C4.6374 17.5761 6.1288 18.2625 7.59246 18.7175C7.61587 18.7246 7.64069 18.7161 7.6556 18.6968C8.00182 18.224 8.31045 17.7254 8.57508 17.2011C8.59068 17.1704 8.57578 17.134 8.54386 17.1218C8.05431 16.9361 7.58817 16.7097 7.13975 16.4526C7.10428 16.4319 7.10143 16.3812 7.13409 16.3569C7.22845 16.2862 7.32284 16.2126 7.41295 16.1383C7.42926 16.1247 7.45197 16.1219 7.47113 16.1304C10.4169 17.4754 13.6062 17.4754 16.5172 16.1304C16.5364 16.1211 16.5591 16.124 16.5761 16.1376C16.6662 16.2119 16.7606 16.2861 16.8556 16.3568C16.8883 16.3811 16.8861 16.4319 16.8507 16.4526C16.4023 16.7147 15.9361 16.9361 15.4459 17.1211C15.414 17.1332 15.3998 17.1704 15.4154 17.2011C15.6857 17.7246 15.9944 18.2232 16.3342 18.696C16.3484 18.716 16.3739 18.7246 16.3973 18.7174C17.8681 18.2625 19.3595 17.5761 20.8991 16.4454C20.9126 16.4354 20.9211 16.4204 20.9225 16.404C21.2978 12.5213 20.2939 9.14857 18.2612 6.1587C18.2562 6.14875 18.2477 6.14161 18.2378 6.13732ZM9.01506 14.3584C8.12817 14.3584 7.39738 13.5442 7.39738 12.5442C7.39738 11.5442 8.11397 10.73 9.01506 10.73C9.92318 10.73 10.6469 11.5514 10.6327 12.5442C10.6327 13.5442 9.91608 14.3584 9.01506 14.3584ZM14.9961 14.3584C14.1092 14.3584 13.3784 13.5442 13.3784 12.5442C13.3784 11.5442 14.095 10.73 14.9961 10.73C15.9042 10.73 16.6279 11.5514 16.6138 12.5442C16.6138 13.5442 15.9043 14.3584 14.9961 14.3584Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
