import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="133" height="48" viewBox="0 0 133 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25.9118 34.0406L19.125 37.959L23.3668 40.408L30.1536 36.4896L25.9118 34.0406Z" fill="#313CCF" />
      <path d="M32.7467 22.2597L9.79688 35.5098L14.0582 37.97L37.008 24.72L32.7467 22.2597Z" fill="#313CCF" />
      <path d="M39.5096 8.57292L34.4375 5.64453L34.4375 17.5898L39.5096 20.5182L39.5096 8.57292Z" fill="#939AF4" />
      <path d="M30.1579 36.4898L23.3711 40.4082L23.3711 45.3062L30.1579 41.3878L30.1579 36.4898Z" fill="#5B65F0" />
      <path d="M23.3668 40.408L19.125 37.959L19.125 42.8569L23.3668 45.3059L23.3668 40.408Z" fill="#939AF4" />
      <path d="M36.9464 24.7351L16.5859 36.4902L16.5859 42.3678L36.9464 30.6127L36.9464 24.7351Z" fill="#5B65F0" />
      <path d="M16.5855 36.49L12.3438 34.041L12.3437 39.9186L16.5855 42.3675L16.5855 36.49Z" fill="#939AF4" />
      <path d="M34.5031 13.4555L4.35547 30.8613L9.02643 33.5581L39.1741 16.1523L34.5031 13.4555Z" fill="#313CCF" />
      <path d="M9.79365 24.708L4.29297 21.5322L4.29297 35.2465L9.79365 38.4223L9.79365 24.708Z" fill="#939AF4" />
      <path d="M4.29688 21.5237L8.5364 19.1006L14.0408 22.2839L9.79904 24.7328L4.29688 21.5237Z" fill="#313CCF" />
      <path d="M39.4952 2.69572L34.3945 5.64062L38.6363 8.0896L43.737 5.1447L39.4952 2.69572Z" fill="#313CCF" />
      <path d="M43.7305 12.9793L43.7305 5.14258L38.6404 8.08135L38.6404 15.9181L43.7305 12.9793Z" fill="#5B65F0" />
      <path d="M43.7309 12.9795L9.79688 32.5713L9.79688 38.4488L43.7309 18.857L43.7309 12.9795Z" fill="#5B65F0" />
      <path d="M14.043 30.1229L14.043 22.2861L9.80121 24.7351L9.80121 32.5718L14.043 30.1229Z" fill="#5B65F0" />
      <path
        d="M132.327 31.0919H131.53L130.25 29.2567C129.936 29.548 129.601 29.8227 129.247 30.0807C128.901 30.3304 128.534 30.5509 128.148 30.7424C127.761 30.9255 127.363 31.0711 126.952 31.1793C126.55 31.2875 126.139 31.3416 125.72 31.3416C124.81 31.3416 123.953 31.1835 123.147 30.8672C122.35 30.5509 121.65 30.0932 121.046 29.4939C120.45 28.8863 119.979 28.1456 119.633 27.2716C119.286 26.3977 119.113 25.4031 119.113 24.2878C119.113 23.2474 119.286 22.2944 119.633 21.4288C119.979 20.5549 120.45 19.8058 121.046 19.1816C121.65 18.5573 122.35 18.0746 123.147 17.7333C123.953 17.3838 124.81 17.209 125.72 17.209C126.139 17.209 126.554 17.2631 126.964 17.3713C127.375 17.4795 127.774 17.6293 128.16 17.8207C128.547 18.0122 128.913 18.2369 129.259 18.4949C129.613 18.7529 129.944 19.0317 130.25 19.3314L131.53 17.7458H132.327V31.0919ZM129.006 24.2878C129.006 23.8217 128.917 23.3723 128.74 22.9395C128.571 22.4983 128.337 22.1113 128.039 21.7784C127.741 21.4371 127.391 21.1666 126.988 20.9669C126.594 20.7588 126.171 20.6548 125.72 20.6548C125.269 20.6548 124.842 20.7338 124.44 20.892C124.045 21.0501 123.699 21.2832 123.401 21.5911C123.111 21.8991 122.882 22.2819 122.713 22.7397C122.544 23.1891 122.459 23.7052 122.459 24.2878C122.459 24.8704 122.544 25.3906 122.713 25.8484C122.882 26.2978 123.111 26.6765 123.401 26.9845C123.699 27.2924 124.045 27.5255 124.44 27.6836C124.842 27.8418 125.269 27.9208 125.72 27.9208C126.171 27.9208 126.594 27.821 126.988 27.6212C127.391 27.4131 127.741 27.1426 128.039 26.8097C128.337 26.4685 128.571 26.0814 128.74 25.6486C128.917 25.2075 129.006 24.7539 129.006 24.2878Z"
        fill="white"
      />
      <path
        d="M115.689 31.0919C114.892 31.0919 114.143 30.9379 113.442 30.63C112.742 30.3137 112.126 29.8851 111.594 29.3441C111.071 28.7947 110.656 28.158 110.35 27.4339C110.052 26.7098 109.903 25.9357 109.903 25.1117V21.1416H108.297V17.7458H109.903V12.4023H113.189V17.7458H118.189V21.1416H113.189V25.1117C113.189 25.4696 113.253 25.8067 113.382 26.123C113.511 26.431 113.688 26.7015 113.913 26.9345C114.139 27.1676 114.405 27.3548 114.711 27.4963C115.017 27.6295 115.343 27.6961 115.689 27.6961H118.189V31.0919H115.689Z"
        fill="white"
      />
      <path
        d="M106.925 31.0919H106.128L104.847 29.2567C104.533 29.548 104.199 29.8227 103.845 30.0807C103.498 30.3304 103.132 30.5509 102.746 30.7424C102.359 30.9255 101.961 31.0711 101.55 31.1793C101.147 31.2875 100.737 31.3416 100.318 31.3416C99.4079 31.3416 98.5504 31.1835 97.7451 30.8672C96.948 30.5509 96.2474 30.0932 95.6435 29.4939C95.0476 28.8863 94.5766 28.1456 94.2303 27.2716C93.8841 26.3977 93.7109 25.4031 93.7109 24.2878C93.7109 23.2474 93.8841 22.2944 94.2303 21.4288C94.5766 20.5549 95.0476 19.8058 95.6435 19.1816C96.2474 18.5573 96.948 18.0746 97.7451 17.7333C98.5504 17.3838 99.4079 17.209 100.318 17.209C100.737 17.209 101.151 17.2631 101.562 17.3713C101.973 17.4795 102.371 17.6293 102.758 17.8207C103.144 18.0122 103.511 18.2369 103.857 18.4949C104.211 18.7529 104.541 19.0317 104.847 19.3314L106.128 17.7458H106.925V31.0919ZM103.603 24.2878C103.603 23.8217 103.515 23.3723 103.337 22.9395C103.168 22.4983 102.935 22.1113 102.637 21.7784C102.339 21.4371 101.989 21.1666 101.586 20.9669C101.192 20.7588 100.769 20.6548 100.318 20.6548C99.8669 20.6548 99.4401 20.7338 99.0375 20.892C98.643 21.0501 98.2967 21.2832 97.9988 21.5911C97.7089 21.8991 97.4794 22.2819 97.3103 22.7397C97.1412 23.1891 97.0567 23.7052 97.0567 24.2878C97.0567 24.8704 97.1412 25.3906 97.3103 25.8484C97.4794 26.2978 97.7089 26.6765 97.9988 26.9845C98.2967 27.2924 98.643 27.5255 99.0375 27.6836C99.4401 27.8418 99.8669 27.9208 100.318 27.9208C100.769 27.9208 101.192 27.821 101.586 27.6212C101.989 27.4131 102.339 27.1426 102.637 26.8097C102.935 26.4685 103.168 26.0814 103.337 25.6486C103.515 25.2075 103.603 24.7539 103.603 24.2878Z"
        fill="white"
      />
      <path
        d="M92.6933 14.0876C92.6933 14.4039 92.6329 14.6994 92.5122 14.9741C92.3994 15.2487 92.2424 15.4901 92.0411 15.6982C91.8478 15.8979 91.6143 16.0602 91.3406 16.1851C91.0748 16.3016 90.793 16.3599 90.4951 16.3599C90.181 16.3599 89.8871 16.3016 89.6133 16.1851C89.3476 16.0602 89.1141 15.8979 88.9128 15.6982C88.7115 15.4901 88.5504 15.2487 88.4296 14.9741C88.3169 14.6994 88.2606 14.4039 88.2606 14.0876C88.2606 13.7797 88.3169 13.4884 88.4296 13.2137C88.5504 12.9307 88.7115 12.6894 88.9128 12.4896C89.1141 12.2815 89.3476 12.1192 89.6133 12.0027C89.8871 11.8779 90.181 11.8154 90.4951 11.8154C90.793 11.8154 91.0748 11.8779 91.3406 12.0027C91.6143 12.1192 91.8478 12.2815 92.0411 12.4896C92.2424 12.6894 92.3994 12.9307 92.5122 13.2137C92.6329 13.4884 92.6933 13.7797 92.6933 14.0876ZM83.8398 36.1855V32.7522L85.6637 32.7772C86.1066 32.7772 86.5172 32.6815 86.8957 32.4901C87.2741 32.307 87.6003 32.0573 87.874 31.741C88.1559 31.433 88.3773 31.0751 88.5384 30.6673C88.6994 30.2595 88.7799 29.8308 88.7799 29.3814V17.7207H92.1498V29.3814H92.1257C92.1176 30.0056 92.033 30.609 91.872 31.1917C91.719 31.7743 91.4976 32.3153 91.2077 32.8147C90.9178 33.3224 90.5716 33.7801 90.1689 34.188C89.7663 34.6041 89.3194 34.9579 88.8282 35.2492C88.3451 35.5488 87.8257 35.7777 87.2701 35.9358C86.7065 36.1023 86.1227 36.1855 85.5187 36.1855H83.8398Z"
        fill="white"
      />
      <path
        d="M84.5928 29.5312C84.3271 29.7809 84.0412 30.0181 83.7352 30.2428C83.4373 30.4592 83.1233 30.6507 82.7931 30.8171C82.463 30.9753 82.1207 31.1001 81.7664 31.1917C81.4202 31.2916 81.0659 31.3415 80.7035 31.3415C79.9064 31.3415 79.1575 31.1958 78.457 30.9045C77.7564 30.6132 77.1404 30.2012 76.6089 29.6685C76.0856 29.1275 75.6709 28.4742 75.3649 27.7084C75.0669 26.9344 74.918 26.0688 74.918 25.1116V17.7207H78.2033V25.1116C78.2033 25.5611 78.2677 25.9648 78.3966 26.3226C78.5334 26.6722 78.7146 26.9677 78.9401 27.2091C79.1656 27.4504 79.4273 27.6335 79.7252 27.7584C80.0312 27.8832 80.3573 27.9457 80.7035 27.9457C81.0417 27.9457 81.3598 27.8666 81.6577 27.7084C81.9637 27.542 82.2294 27.3256 82.4549 27.0592C82.6804 26.7929 82.8575 26.4933 82.9864 26.1603C83.1152 25.8191 83.1796 25.4695 83.1796 25.1116V17.7207H86.477V31.0918H85.6799L84.5928 29.5312Z"
        fill="white"
      />
      <path
        d="M65.3216 31.0919H62V12.4023H65.3216V24.1879L70.2375 17.7458H74.0301L69.7423 23.314L74.0301 31.0919H70.2375L67.6165 26.2354L65.3216 29.3815V31.0919Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
