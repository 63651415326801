import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = ({ disabled, ...props }) => (
  <Svg
    disabled={disabled}
    width="14" 
    height="14" 
    viewBox="0 0 14 14"
    fill="none"
    color="primaryDark"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path 
      d="M11.5183 2.48007C10.5471 1.50585 9.28778 0.870513 7.92709 0.668218C6.5664 0.465924 5.17673 0.707443 3.96409 1.35698C2.75144 2.00651 1.78035 3.02948 1.19476 4.27427C0.60917 5.51905 0.440248 6.9194 0.713031 8.26773C0.985815 9.61606 1.68579 10.8406 2.70919 11.7599C3.73259 12.6791 5.02496 13.2442 6.39472 13.3713C7.76449 13.4984 9.13875 13.1807 10.3138 12.4654C11.4889 11.7501 12.4022 10.6752 12.9183 9.40007H11.1423C10.6479 10.2734 9.89207 10.9698 8.98125 11.3913C8.07043 11.8129 7.05039 11.9383 6.06459 11.7499C5.0788 11.5616 4.17683 11.069 3.48553 10.3414C2.79424 9.61384 2.3484 8.68787 2.21071 7.69373C2.07301 6.69959 2.2504 5.68731 2.71792 4.79922C3.18543 3.91114 3.91956 3.19194 4.81706 2.74276C5.71456 2.29359 6.73028 2.13703 7.72138 2.29512C8.71248 2.4532 9.6291 2.91797 10.3423 3.62407L7.79833 6.20007H13.3983V0.600066L11.5183 2.48007Z" 
      fill={props.fill ?? '#5E5E5E'}
    />
  </Svg>
);

export default Icon;
