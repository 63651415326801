import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25.9118 34.0406L19.125 37.959L23.3668 40.408L30.1536 36.4896L25.9118 34.0406Z" fill="#313CCF" />
      <path d="M32.7467 22.2597L9.79688 35.5098L14.0582 37.97L37.008 24.72L32.7467 22.2597Z" fill="#313CCF" />
      <path d="M39.5096 8.57292L34.4375 5.64453L34.4375 17.5898L39.5096 20.5182L39.5096 8.57292Z" fill="#939AF4" />
      <path d="M30.1579 36.4898L23.3711 40.4082L23.3711 45.3062L30.1579 41.3878L30.1579 36.4898Z" fill="#5B65F0" />
      <path d="M23.3668 40.408L19.125 37.959L19.125 42.8569L23.3668 45.3059L23.3668 40.408Z" fill="#939AF4" />
      <path d="M36.9464 24.7351L16.5859 36.4902L16.5859 42.3678L36.9464 30.6127L36.9464 24.7351Z" fill="#5B65F0" />
      <path d="M16.5855 36.49L12.3438 34.041L12.3437 39.9186L16.5855 42.3675L16.5855 36.49Z" fill="#939AF4" />
      <path d="M34.5031 13.4555L4.35547 30.8613L9.02643 33.5581L39.1741 16.1523L34.5031 13.4555Z" fill="#313CCF" />
      <path d="M9.79365 24.708L4.29297 21.5322L4.29297 35.2465L9.79365 38.4223L9.79365 24.708Z" fill="#939AF4" />
      <path d="M4.29688 21.5237L8.5364 19.1006L14.0408 22.2839L9.79904 24.7328L4.29688 21.5237Z" fill="#313CCF" />
      <path d="M39.4952 2.69572L34.3945 5.64062L38.6363 8.0896L43.737 5.1447L39.4952 2.69572Z" fill="#313CCF" />
      <path d="M43.7305 12.9793L43.7305 5.14258L38.6404 8.08135L38.6404 15.9181L43.7305 12.9793Z" fill="#5B65F0" />
      <path d="M43.7309 12.9795L9.79688 32.5713L9.79688 38.4488L43.7309 18.857L43.7309 12.9795Z" fill="#5B65F0" />
      <path d="M14.043 30.1229L14.043 22.2861L9.80121 24.7351L9.80121 32.5718L14.043 30.1229Z" fill="#5B65F0" />
    </Svg>
  );
};

export default Icon;
