import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const polygonTokens = {
  native: new ERC20Token( // Matic
    ChainId.POLYGON,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'MATIC',
    'Matic Token',
    'https://polygon.technology/',
  ),
  usdt: new ERC20Token(ChainId.POLYGON, '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 6, 'USDT', 'USD Token', ''),
  // galx: new ERC20Token(ChainId.POLYGON, '0xB0C8E76d3dfcFA24539960e8C4c968380c915F6c', 18, 'GALX', 'Galux', ''),
}
