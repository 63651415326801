import { ChainId, JSBI, Percent, Token, WETH9, WNATIVE } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  bscTokens,
  bscTestnetTokens,
  USDT,
  GALX_FUJI,
  GALX_POL_TEST,
  USDT_POL_TEST,
  GALX_ETH,
  polygonTokens,
  bahamutTokens,
} from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0xE1eDE671EF7D601C3eD8f467608caE20e0F53Fa1',
  [ChainId.BSC]: '0xb57004409FF3eF3eD3929A6dB80a9AA5DD1c2ECE', //router address
  [ChainId.POLYGON]: '0xE1eDE671EF7D601C3eD8f467608caE20e0F53Fa1',
  [ChainId.POLYGON_TESTNET]: '0xB40F89482a65c56756fce2B9F596Bb88348Cc19E',
  [ChainId.FUJI]: '0x037af3769dD31735373ED1f92F0FAA3a9eF71Ac4',
  [ChainId.BSC_TESTNET]: '0xb57004409FF3eF3eD3929A6dB80a9AA5DD1c2ECE',
  [ChainId.BAHAMUT]: '0x90B74764FFfcA7aD47594A14c540794027beB50e',
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  [ChainId.ETHEREUM]: [GALX_ETH, USDT[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM]],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.busd, bscTokens.native],
  [ChainId.POLYGON]: [polygonTokens.usdt, polygonTokens.native],
  [ChainId.POLYGON_TESTNET]: [GALX_POL_TEST, USDT_POL_TEST],
  [ChainId.FUJI]: [GALX_FUJI, GALX_FUJI, WNATIVE[ChainId.FUJI]],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.usdt, bscTestnetTokens.ftn],
  [ChainId.BAHAMUT]: [bahamutTokens.usdt, bahamutTokens.ftn, bahamutTokens.usdc],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {
    // SNFTS-SFUND
  },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES = {
  [ChainId.ETHEREUM]: [USDT[ChainId.ETHEREUM], GALX_ETH],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.busd],
  [ChainId.POLYGON]: [polygonTokens.usdt],
  [ChainId.POLYGON_TESTNET]: [GALX_POL_TEST, USDT_POL_TEST],
  [ChainId.FUJI]: [GALX_FUJI, GALX_FUJI, WNATIVE[ChainId.FUJI]],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.ftn, bscTestnetTokens.usdt],
  [ChainId.BAHAMUT]: [bahamutTokens.usdt, bahamutTokens.usdc],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  [ChainId.ETHEREUM]: [GALX_ETH, USDT[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM]],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.busd, bscTokens.native],
  [ChainId.POLYGON]: [polygonTokens.usdt, polygonTokens.native],
  [ChainId.POLYGON_TESTNET]: [GALX_POL_TEST, USDT_POL_TEST],
  [ChainId.FUJI]: [GALX_FUJI, GALX_FUJI, WNATIVE[ChainId.FUJI]],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.usdt, bscTestnetTokens.ftn],
  [ChainId.BAHAMUT]: [bahamutTokens.usdt, bahamutTokens.ftn, bahamutTokens.usdc],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [GALX_ETH, USDT[ChainId.ETHEREUM]],
    [GALX_ETH, WNATIVE[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.busd, bscTokens.native],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.usdt, bscTokens.native],
  ],
  [ChainId.POLYGON]: [[polygonTokens.native, polygonTokens.usdt]],
  [ChainId.BAHAMUT]: [
    [bahamutTokens.usdt, bahamutTokens.ftn],
    [bahamutTokens.usdc, bahamutTokens.ftn],
    [bahamutTokens.usdc, bahamutTokens.usdt],
  ],

  //TODO new CHAIN
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(20), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
