import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

// import bg from '../public/images/1.png'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Quicksand', sans-serif;
  }
  body {
    touch-action: pan-y;
    position: relative;
    background:  ${({ theme }) => theme.colors.backgroundAlt};
    video::-webkit-media-controls {
      display: none !important;
    }
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
