import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M4.10355 11.4818C4.10355 11.4818 12.0645 8.21457 14.8255 7.06412C15.8839 6.60397 19.4733 5.13138 19.4733 5.13138C19.4733 5.13138 21.1299 4.48718 20.9918 6.05173C20.9458 6.696 20.5777 8.9508 20.2095 11.3897C19.6573 14.841 19.0591 18.6144 19.0591 18.6144C19.0591 18.6144 18.9671 19.6728 18.1848 19.8569C17.4025 20.041 16.114 19.2127 15.8839 19.0286C15.6998 18.8906 12.4326 16.8198 11.2362 15.8074C10.9141 15.5313 10.5459 14.9791 11.2822 14.3348C12.9388 12.8163 14.9175 10.9296 16.114 9.73315C16.6662 9.18091 17.2184 7.89244 14.9175 9.45699C11.6504 11.7119 8.42916 13.8287 8.42916 13.8287C8.42916 13.8287 7.69286 14.2888 6.31237 13.8746C4.93181 13.4605 3.32121 12.9083 3.32121 12.9083C3.32121 12.9083 2.21686 12.2181 4.10355 11.4818Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
