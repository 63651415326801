import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Flex } from "../../components";
import { MenuContext } from "./context";
import Link from "next/link";

interface Props {
  href: string;
  text: string;
  blank?: boolean;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  .mobile-icon {
    fill: none;
    /* width: 32px; */
    ${({ theme }) => theme.mediaQueries.lg} {
      display: none;
    }
  }
  .desktop-icon {
    /* width: 160px; */
    display: none;
    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  }
  .eye {
    animation-delay: 20ms;
  }
  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`;

const Logo: React.FC<React.PropsWithChildren<Props>> = ({ href, text, blank }) => {
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = href.startsWith("http");

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink passHref target={blank ? "_blank" : ""} href={href} aria-label="Bahamut home page">
          {text}
        </StyledLink>
      ) : (
        <StyledLink passHref href={href} as={linkComponent} aria-label="Bahamut home page">
          {text}
        </StyledLink>
      )}
    </Flex>
  );
};

export default React.memo(Logo);
