import React from "react";
import styled from "styled-components";
import { SvgProps } from "../types";
import Svg from "../Svg";

// const SvgIcon = styled.svg`
//   color: #323638;
//   ${({ theme }) => theme.mediaQueries.lg} {
//     color: #ffffff;
//   }
//   height: 20px;
// `;

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.6 4.55556H11V3H4.6C4.17565 3 3.76869 3.16389 3.46863 3.45561C3.16857 3.74733 3 4.143 3 4.55556V15.4444C3 15.857 3.16857 16.2527 3.46863 16.5444C3.76869 16.8361 4.17565 17 4.6 17H11V15.4444H4.6V4.55556Z"
        fill="white"
      />
      <path d="M12.2857 6V9.2H6V10.8H12.2857V14L17 10L12.2857 6Z" fill="white" />
    </Svg>
  );
};

export default Icon;
