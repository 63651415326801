import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Box, Flex } from "../../components/Box";
import Footer from "../../components/Footer";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { HeaderIcon, Polygon } from "./Icons";
import Link from "./Link";
import { NavProps, RoutesProps } from "./types";
import LangSelector from "../../components/LangSelector/LangSelector";
import {
  CloseIcon,
  LiquidityIcon,
  SwapIcon,
  FarmingIcon,
  KujataLogoIcon,
  KujataLogoIconMobile,
  Button,
} from "../../components";
import { useTranslation } from "@pancakeswap/localization";
import NextLink from "next/link";

const Wrapper = styled.div`
  /* position: relative; */
  background-image: url(${({ theme }) => theme.isDark && "/images/background.png"});
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(10px);
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding-bottom: 20px;
  min-height: calc(100vh - 50px);
  @media (min-width: 790px) {
    min-height: calc(100vh - 50px);
  }
`;

const StyledNavWrapper = styled.div`
  z-index: 10;
  position: fixed;
  width: 100%;
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundGrey}; */
`;

const StyledNav = styled.nav<{ showMobileMenu: boolean }>`
  display: flex;
  justify-content: end;
  padding: 11px 16px;
  background-color: ${({ theme, showMobileMenu }) => showMobileMenu && theme.colors.black};
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 21px 40px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    max-width: 1055px;
  }
  margin: 0 auto;
  width: 100%;
`;

const DescWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
  }
`;

const ListRoutes = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 96px;
  padding-top: 56px;

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 80px;
    flex-direction: row;
    padding-top: 0;
  }
  align-items: center;
  :last-child {
    position: relative;
  }
  .dropdown {
    .up {
      display: none;
    }
    :hover {
      div {
        display: flex;
      }

      .down {
        display: none;
      }
      .up {
        display: flex;
      }
    }
  }
`;

const RouteItem = styled.li<{ fontWeight?: string; choosed?: boolean }>`
  font-weight: 500;
  display: flex;
  text-align: center;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  color: ${({ choosed }) => (choosed ? "#FFFFFF" : "#CBCBCB")};
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
  }
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  a {
    white-space: nowrap;
    align-items: center;
    display: flex;
  }
  svg {
    fill: ${({ choosed }) => (choosed ? "#FFFFFF" : "#5E5E5E")};
  }
`;

const BodyWrapper = styled(Box)`
  margin-top: 0;
  /* position: relative; */
  display: flex;
  max-width: 100vw;
  padding-top: 100px;
  padding-bottom: 45px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-bottom: 100px;
    padding-top: 137px;
  }
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MobileDropdown = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 14px;
  background: ${({ theme }) => theme.colors.black};
  height: 100vh;
  width: 100%;
`;
const RightSideWrapper = styled.div`
  /* position: relative; */
  /* z-index: 999; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const Routes: React.FC<React.PropsWithChildren<RoutesProps>> = ({ homeLink, closeMobile }) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();
  const router = useRouter();
  const handleNavigation = (path: string) => {
    if (closeMobile) closeMobile();
    router.push(homeLink?.href ?? path);
  };
  return (
    <ListRoutes>
      <RouteItem choosed={window.location.pathname === "/"}>
        <SwapIcon marginRight="8px" />
        <Button variant="text" style={{ color: "#fff", padding: 0 }} onClick={() => handleNavigation("/")}>
          <Link href={homeLink?.href ?? "/"} text={t("Swap")} />
        </Button>
      </RouteItem>
      <RouteItem choosed={window.location.pathname === "/liquidity"}>
        <LiquidityIcon marginRight="8px" />
        <Button variant="text" style={{ color: "#fff", padding: 0 }} onClick={() => handleNavigation("/liquidity")}>
          <Link href={homeLink?.href ?? "/liquidity"} text={t("Liquidity")} />
        </Button>
      </RouteItem>
      <RouteItem choosed={false}>
        <FarmingIcon marginRight="8px" />
        <Button variant="text" style={{ color: "#fff", padding: 0 }}>
          <Link href={"https://staking.kujata.io"} text={t("Farming")} />
        </Button>
      </RouteItem>
    </ListRoutes>
  );
};

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  links,
  subLinks,
  children,
  currentLang,
  langs,
  setLang,
}) => {
  // const linkRoute = useContext(MenuContext);
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const {
    t,
    currentLanguage: { code },
  } = useTranslation();
  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

  const isToggleMenu = () => setIsShowMobileMenu((prev) => !prev);

  const handleResize = () => {
    if (window.innerWidth > 769) {
      setIsShowMobileMenu(false);
    }
  };

  useEffect(() => {
    console.log("homeLink?.href", window.location.pathname);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <AtomBox
        asChild
        minHeight={{
          xs: "100vh",
          md: "100vh",
        }}
      >
        <Wrapper>
          <StyledNavWrapper>
            <StyledNav showMobileMenu={isShowMobileMenu}>
              <Flex mr="80px">
                {!isMobile ? (
                  <RouteItem>
                    <NextLink href="/">
                      <KujataLogoIcon />
                    </NextLink>
                  </RouteItem>
                ) : (
                  <RouteItem as="a" href="/swap">
                    <NextLink href="/">
                      <KujataLogoIconMobile />
                    </NextLink>
                  </RouteItem>
                )}
              </Flex>
              <DescWrapper>
                <Routes homeLink={homeLink} />
              </DescWrapper>
              <RightSideWrapper>
                {rightSide}
                <IconWrapper onClick={isToggleMenu}>
                  {isShowMobileMenu ? <CloseIcon width={24} height={24} color="#fff" /> : <HeaderIcon />}
                </IconWrapper>
              </RightSideWrapper>
            </StyledNav>
            {isShowMobileMenu && (
              <MobileDropdown>
                <Routes homeLink={homeLink} closeMobile={isToggleMenu} />
              </MobileDropdown>
            )}
          </StyledNavWrapper>

          <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : "0"}>
            <Inner>{children}</Inner>
          </BodyWrapper>
        </Wrapper>
      </AtomBox>
      <Footer />
    </MenuContext.Provider>
  );
};

export default Menu;
