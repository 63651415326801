import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { Menu as UikitMenu, NextLinkFromReactRouter, SwitchThemeIcon } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import GlobalSettings from './GlobalSettings'
// import { HeaderIcon } from '@pancakeswap/uikit/src/widgets/Menu/Icons'
import { SettingsMode } from './GlobalSettings/types'
import { MenuMobile } from './UserMenu/MenuMobile'
import { HeaderIcon } from './Svg/MenuIcons'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useAccount } from 'wagmi'
import { useTheme as useNextTheme } from 'next-themes'

function Icon(props) {
  return (
    <svg width={20} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#prefix__clip0_1010_45147)">
        <path
          d="M15.104 5.501c-.364-.214-.833-.214-1.25 0l-2.916 1.763-1.98 1.122-2.864 1.762c-.365.214-.834.214-1.25 0L2.604 8.76a1.309 1.309 0 01-.625-1.122V4.967c0-.427.208-.855.625-1.122l2.24-1.335c.364-.214.833-.214 1.25 0l2.24 1.389c.364.213.624.64.624 1.121v1.763l1.98-1.175V3.792c0-.428-.209-.855-.626-1.122L6.146.16c-.365-.214-.833-.214-1.25 0L.625 2.722C.208 2.937 0 3.364 0 3.792v5.021c0 .427.208.855.625 1.122l4.219 2.51c.364.214.833.214 1.25 0l2.864-1.709 1.98-1.175 2.864-1.71c.365-.213.833-.213 1.25 0l2.24 1.336c.364.214.625.64.625 1.122v2.67c0 .428-.209.855-.625 1.122l-2.188 1.336c-.364.213-.833.213-1.25 0l-2.24-1.336a1.309 1.309 0 01-.624-1.122V11.27l-1.98 1.175v1.763c0 .427.209.855.625 1.122l4.22 2.51c.364.214.832.214 1.25 0l4.218-2.51c.364-.214.625-.641.625-1.122V9.133c0-.427-.208-.854-.625-1.121l-4.219-2.51z"
          fill="#8247E5"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1010_45147">
          <path fill="#fff" transform="translate(0 .053)" d="M0 0h20v17.895H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: ${({ theme }) => theme.toggle.handleBackground};
  width: 40px;
  height: 40px;
  border-radius: 12px;
`

const Network = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #29221f;
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 1020px) {
    display: none;
  }
`

const UserMenuWrapper = styled.div`
  display: flex;
  justify-content: end;
  white-space: nowrap;
`

const Menu = (props) => {
  const { address: account } = useAccount()
  const { isDark, theme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const { setTheme } = useNextTheme()

  // const [showPhishingWarningBanner] = usePhishingBannerManager()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  // const toggleTheme = useMemo(() => {
  //   return () => setTheme(isDark ? 'light' : 'dark')
  // }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const [isShowMenuMobile, setIsShowMenuMobile] = useState<boolean>(false)

  const handleResize = () => {
    if (window.innerWidth > 1023) {
      setIsShowMenuMobile(false)
    }
  }
  const toggleMenuMobile = () => setIsShowMenuMobile((prev) => !prev)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <>
            {/* <WrapperIcon onClick={() => setTheme(isDark ? 'light' : 'dark')}>
              <SwitchThemeIcon color={theme.toggle.iconColor} />
            </WrapperIcon> */}
            <UserMenuWrapper>
              <UserMenu isInHeader />
            </UserMenuWrapper>
          </>
        }
        isDark={true}
        // toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        {...props}
      />
    </>
  )
}

export default Menu
