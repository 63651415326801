import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg {...props} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.27466 8.91655C8.22306 8.94815 8.1782 8.9896 8.14263 9.03855C8.10706 9.0875 8.08149 9.14298 8.06738 9.20182C8.05327 9.26065 8.0509 9.32169 8.06039 9.38145C8.06989 9.44121 8.09107 9.4985 8.12273 9.55007C8.54584 10.2398 8.90542 10.9244 9.14483 11.5464C9.18866 11.6604 9.27598 11.7523 9.38758 11.8019C9.49918 11.8515 9.62591 11.8547 9.7399 11.8109C9.85389 11.767 9.94579 11.6797 9.99539 11.5681C10.045 11.4565 10.0482 11.3298 10.0044 11.2158C9.73829 10.5234 9.34786 9.78625 8.90772 9.06848C8.84391 8.9644 8.74137 8.88992 8.62265 8.86143C8.50393 8.83294 8.37876 8.85276 8.27466 8.91655ZM13.0693 8.47732C13.1678 8.54939 13.2337 8.65762 13.2524 8.77823C13.2712 8.89884 13.2412 9.02195 13.1692 9.12051C12.6365 9.84933 12.1674 10.824 11.8373 11.568C11.8127 11.6233 11.7775 11.6732 11.7337 11.7148C11.6899 11.7565 11.6383 11.7891 11.5818 11.8108C11.5254 11.8325 11.4653 11.8429 11.4048 11.8414C11.3444 11.8399 11.2848 11.8265 11.2295 11.8019C11.1743 11.7774 11.1244 11.7422 11.0827 11.6983C11.0411 11.6545 11.0084 11.6029 10.9867 11.5465C10.965 11.49 10.9546 11.4299 10.9562 11.3694C10.9577 11.309 10.9711 11.2494 10.9957 11.1942C11.329 10.4437 11.8308 9.39123 12.4261 8.57723C12.4982 8.47872 12.6064 8.41286 12.727 8.39413C12.8477 8.3754 12.9708 8.40532 13.0693 8.47732Z"
        fill="#5E5E5E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9899 4.49235C13.0682 3.25939 18.8339 1.71289 18.8339 1.71289C18.8339 1.71289 17.8537 7.88001 16.9913 8.8662C16.349 9.60101 14.3025 9.14198 13.2998 8.85976C14.0493 7.71012 14.9181 6.5872 15.6819 5.69585C15.7599 5.60297 15.798 5.48311 15.7881 5.36227C15.7782 5.24144 15.721 5.12939 15.629 5.05046C15.5369 4.97153 15.4175 4.93208 15.2966 4.94068C15.1756 4.94928 15.063 5.00524 14.983 5.09641C14.2128 5.99512 13.32 7.14521 12.5383 8.34088C12.0991 7.5232 11.1373 5.46749 11.9899 4.49235ZM4.51296 12.7626H2.21094V18.2875H4.51296V17.3469C4.98257 17.3275 5.67594 17.3027 5.91582 17.3123C6.73856 17.3459 7.35228 17.5421 7.97382 17.7405C8.55301 17.9251 9.13819 18.1121 9.90384 18.1705C10.0963 18.1853 10.2837 18.2041 10.4655 18.2221C11.1207 18.2884 11.7022 18.3473 12.1828 18.1705C12.7961 17.9449 16.3016 16.229 16.7399 15.7773C17.1782 15.3261 16.9591 14.107 15.6004 14.3326C14.9301 14.444 14.1534 14.7644 13.4485 15.055C12.7252 15.3528 12.0779 15.6199 11.7003 15.5969C10.9554 15.5517 8.98395 15.1908 8.98395 15.1908L11.2478 15.2184C11.2478 15.2184 11.5677 15.235 12.0074 14.9196C12.4471 14.6042 12.8398 13.7005 12.1824 13.7005C11.5249 13.7005 10.8242 13.4749 10.8242 13.4749L8.0194 12.8427C8.0194 12.8427 7.05532 12.6622 6.66121 12.8427C6.33616 12.9914 4.98165 13.4767 4.51296 13.6438V12.7626ZM3.53598 3.40258C3.53598 3.40258 8.29427 4.67882 9.18377 5.69585C9.87115 6.48176 9.13036 8.1185 8.75743 8.82292C8.26865 8.05537 7.74095 7.3133 7.1764 6.59963C7.13895 6.55217 7.09251 6.51255 7.03974 6.48303C6.98698 6.45352 6.92891 6.43468 6.86887 6.42761C6.80882 6.42053 6.74797 6.42535 6.68979 6.44179C6.6316 6.45823 6.57723 6.48598 6.52977 6.52343C6.4823 6.56089 6.44268 6.60732 6.41317 6.66009C6.38365 6.71286 6.36482 6.77092 6.35774 6.83097C6.35067 6.89102 6.35549 6.95187 6.37193 7.01005C6.38837 7.06823 6.41611 7.12261 6.45357 7.17007C7.00228 7.86378 7.51493 8.58527 7.98948 9.33167C7.12944 9.56464 5.56315 9.88554 5.0567 9.30589C4.34491 8.49189 3.53598 3.40212 3.53598 3.40212"
        fill="#5E5E5E"
      />
    </Svg>
  );
};

export default Icon;
