import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M16.6527 4.6875H19.1337L13.7134 10.8825L20.0899 19.3125H15.0964L11.1859 14.1997L6.71141 19.3125H4.22891L10.0264 12.6862L3.91016 4.6875H9.02891L12.5637 9.36075L16.6527 4.6875ZM15.7819 17.8275H17.1567L8.28266 6.0945H6.80741L15.7819 17.8275Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
