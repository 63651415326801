import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.2908 3.86002L1.82075 18C1.64612 18.3024 1.55372 18.6453 1.55274 18.9945C1.55176 19.3438 1.64224 19.6872 1.81518 19.9905C1.98812 20.2939 2.23748 20.5468 2.53846 20.7239C2.83944 20.901 3.18155 20.9962 3.53075 21H20.4708C20.82 20.9962 21.1621 20.901 21.463 20.7239C21.764 20.5468 22.0134 20.2939 22.1863 19.9905C22.3593 19.6872 22.4497 19.3438 22.4488 18.9945C22.4478 18.6453 22.3554 18.3024 22.1808 18L13.7108 3.86002C13.5325 3.56613 13.2815 3.32314 12.9819 3.15451C12.6824 2.98587 12.3445 2.89728 12.0008 2.89728C11.657 2.89728 11.3191 2.98587 11.0196 3.15451C10.72 3.32314 10.469 3.56613 10.2908 3.86002V3.86002Z"
        stroke="#F77C80"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 9V13" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 17H12.01" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Icon;
