import { darkColors, lightColors, baseColors } from "../../theme/colors";
import { AlertTheme } from "./types";

export const light: AlertTheme = {
  background: baseColors.primaryDark,
};

export const dark: AlertTheme = {
  background: baseColors.primaryDark,
};
