import { ChainId, Token, WBNB, ERC20Token } from '@pancakeswap/sdk'
import { USDC_BSC, CP, BUSD_BSC } from './common'

export const bscTokens = {
  // wbnb: WBNB[ChainId.BSC], // Wrapped Matic
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  native: new ERC20Token( // Matic
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB Token',
    'https://www.binance.com/',
  ),
  cp: CP,
  nft: new ERC20Token(
    ChainId.BSC,
    '0x1fC9004eC7E5722891f5f38baE7678efCB11d34D',
    6,
    'NFT',
    'APENFT',
    'https://apenft.org',
  ),
  stephero: new ERC20Token(
    ChainId.BSC,
    '0xE8176d414560cFE1Bf82Fd73B986823B89E4F545',
    18,
    'HERO',
    'StepHero',
    'https://stephero.io/',
  ),
  pros: new ERC20Token(
    ChainId.BSC,
    '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405',
    18,
    'PROS',
    'Prosper',
    'https://prosper.so/',
  ),
  qbt: new ERC20Token(
    ChainId.BSC,
    '0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
    18,
    'QBT',
    'Qubit Token',
    'https://qbt.fi/',
  ),
  cvp: new ERC20Token(
    ChainId.BSC,
    '0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E',
    18,
    'CVP',
    'Concentrated Voting Power Token',
    'https://powerpool.finance/',
  ),
  bscdefi: new ERC20Token(
    ChainId.BSC,
    '0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3',
    18,
    'BSCDEFI',
    'ChainId.BSC Defi blue chips token',
    'https://powerpool.finance/',
  ),
  busd: BUSD_BSC, // USDC Matic
  usdc: USDC_BSC,
  usdt: new ERC20Token(ChainId.BSC, '0x55d398326f99059ff775485246999027b3197955', 18, 'USDT', 'USD Token', ''),
  // galx: new ERC20Token(ChainId.BSC, '0xf3a1Dd0F9b3C9fBC904A7714506A7BB30180F41A', 18, 'GALX', 'Galux', ''),
  wmatic: new ERC20Token(ChainId.BSC, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'MATIC', 'Matic', ''),
  //TODO new TOKEN
}
