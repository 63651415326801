import { backgroundColor, fontSize, fontWeight, padding } from "styled-system";
import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.MD]: {
    height: "48px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "40px",
    padding: "0 8px",
  },
  [scales.SMXS]: {
    height: "32px",
    fontSize: "12px",
    padding: "4px 24px",
  },
  [scales.XS]: {
    height: "23px",
    fontSize: "12px",
    padding: "0 8px",
    fontWeight: "400",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: "primary",
    color: "invertedContrast",
  },
  [variants.PRIMARYBORDER]: {
    backgroundColor: "primary",
    color: "invertedContrast",
    border: "1px solid #55B3F4",
  },
  [variants.OUTLINE]: {
    backgroundColor: "primaryDark",
    boxShadow: "none",
    border: "1px solid #D9D9D91A",
    color: "#FFFFFF",
  },
  [variants.SECONDARY]: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: "primary",
    boxShadow: "none",
    color: "primary",
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: "primaryDark",
    boxShadow: "none",
    border: "1px solid #5B65F0",
    borderRadius: "12px",
    color: "#FFFFFF",
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "backgroundAlt",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",
  },
  [variants.LIGHT]: {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none",
  },
  [variants.PERCENT]: {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none",
    border: "1px solid rgba(217, 217, 217, 0.1)",
    borderRadius: "12px",
  },
  [variants.SELECT]: {
    color: "textSubtle",
    boxShadow: "none",
    border: "1px solid rgba(217, 217, 217, 0.1)",
    borderRadius: "8px",
    backgroundColor: "select",
  },
  [variants.STAKE]: {
    color: "white",
    backgroundColor: "#D40E81",
  },
  [variants.LEARN]: {
    color: "white",
    backgroundColor: "transparent",
  },
};
