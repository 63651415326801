import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const HelpSvg = styled.svg`
  /* margin-left: 4px; */
  &:hover {
    fill: #1db8e9;
    & > path {
      stroke: #fff;
    }

    & > path:nth-child(1) {
      stroke: #1db8e9;
      fill: #1db8e9;
    }
  }
`;

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <HelpSvg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.49967 14.6668C12.1816 14.6668 15.1663 11.6821 15.1663 8.00016C15.1663 4.31826 12.1816 1.3335 8.49967 1.3335C4.81778 1.3335 1.83301 4.31826 1.83301 8.00016C1.83301 11.6821 4.81778 14.6668 8.49967 14.6668Z"
        stroke="#1094CC"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.55957 5.99989C6.71631 5.55434 7.02567 5.17863 7.43287 4.93931C7.84008 4.7 8.31883 4.61252 8.78435 4.69237C9.24988 4.77222 9.67211 5.01424 9.97629 5.37558C10.2805 5.73691 10.4469 6.19424 10.4462 6.66656C10.4462 7.99989 8.44624 8.66656 8.44624 8.66656"
        stroke="#1094CC"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.5 11.3335H8.50667" stroke="#1094CC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </HelpSvg>
  );
};

export default Icon;
