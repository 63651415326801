import { ModalV2 } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { CHAIN_IDS } from 'utils/wagmi'
import { ChainId } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import { useNetwork } from 'wagmi'
import { atom, useAtom } from 'jotai'
import { SUPPORTED_CHAINS } from 'config/constants/supportChains'
import { UnsupportedNetworkModal } from './UnsupportedNetworkModal'
import { WrongNetworkModal } from './WrongNetworkModal'
import { PageNetworkSupportModal } from './PageNetworkSupportModal'

export const hideWrongNetworkModalAtom = atom(false)

export const NetworkModal = ({ pageSupportedChains = SUPPORTED_CHAINS }: { pageSupportedChains?: number[] }) => {
  const pageSupportedChainss =
    process.env.NEXT_PUBLIC_PROD === 'true'
      ? [SUPPORTED_CHAINS[SUPPORTED_CHAINS.length - 1]]
      : SUPPORTED_CHAINS.slice(3, 6)
  const { chainId, chain, isWrongNetwork, isConnected } = useActiveWeb3React()
  const { chains } = useNetwork()
  const [dismissWrongNetwork, setDismissWrongNetwork] = useAtom(hideWrongNetworkModalAtom)

  // const isBNBOnlyPage = useMemo(() => {
  //   return pageSupportedChainss?.length === 1 && pageSupportedChainss[0] === ChainId.BSC
  // }, [pageSupportedChainss])

  const isPageNotSupported = useMemo(
    () => Boolean(pageSupportedChainss.length) && !pageSupportedChainss.includes(chainId) && isConnected,
    [chainId, pageSupportedChainss],
  )

  if (false) {
    return (
      <ModalV2 isOpen closeOnOverlayClick={false}>
        <PageNetworkSupportModal />
      </ModalV2>
    )
  }

  if (isWrongNetwork && !dismissWrongNetwork && !isPageNotSupported) {
    const currentChain = chains.find((c) => c.id === chainId)
    if (!currentChain) return null
    return (
      <ModalV2 isOpen={isWrongNetwork} closeOnOverlayClick={false} onDismiss={() => setDismissWrongNetwork(true)}>
        <WrongNetworkModal currentChain={currentChain} onDismiss={() => setDismissWrongNetwork(true)} />
      </ModalV2>
    )
  }

  if (((chain?.unsupported ?? true) || isPageNotSupported) && isConnected) {
    return (
      <ModalV2 isOpen closeOnOverlayClick={false}>
        <UnsupportedNetworkModal
          pageSupportedChains={pageSupportedChainss?.length ? pageSupportedChainss : CHAIN_IDS}
        />
      </ModalV2>
    )
  }

  return null
}
