export const ENV_PROD = process.env.NEXT_PUBLIC_PROD === 'true'

console.log('env NEXT_PUBLIC_PROD', process.env.NEXT_PUBLIC_PROD)
export const CHAIN_ID = ENV_PROD ? 5165 : 97

export const MULTICALL_ADDRESS = ENV_PROD
  ? '0x816Cc673751857bFBbB9e670f27c0241F58146C0'
  : '0xEE70ffeb98002206161D91889804EC7294130b07'
export const TJAY_ADDRESS = ENV_PROD
  ? '0xDA2A15aAC7655424F521BcE92DDE2eA1782c95d0'
  : '0x3c9e888173ca67B20117e1dE30146415f9D32B1D'
export const CP_ADDRESS = ENV_PROD
  ? '0xf9D3d8B25b95BCDA979025B74fDFa7ac3F380f9f'
  : '0x6bE8aB6d6ed8AdAb874b5bfb33967D1aD556fBa6'
export const WBNB_ADDRESS = ENV_PROD
  ? '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  : '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889'
export const BUSD_ADDRESS = ENV_PROD
  ? '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
  : '0xd501Da8574DfA72B5FAEd70c605aE995898bBA32'
export const DAI_COIN = ENV_PROD
  ? '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
  : '0x0Ad303589DdF89481d9919CC9b9a9c8eD07a1D62'
export const NXDT_COIN = ENV_PROD
  ? '0x803a6f46a6d1ca52Ce3bD84743309cDD8313004f'
  : '0xF175Bb19b01D096D2F4c32DE8C6155665ca2fA77'
export const NXD_COIN = ENV_PROD
  ? '0x228b5C21ac00155cf62c57bcc704c0dA8187950b'
  : '0x994cf18FC65c1c6d7798646ff42e554C8A635802'
export const WBTC = ENV_PROD
  ? '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'
  : '0xEAD7dcA2543126A5F8653Af2D532A162fCa05240'
export const WETH = ENV_PROD
  ? '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'
  : '0xcDcd3C78b37Ef89dBD9Cf11258125fBb9b2D2bE2'
export const LYDIA = ENV_PROD
  ? '0x3B0119D41cAa1F418aC085A07D66982F9801025E'
  : '0x48ff3fA82D6aeC1644DB9E72ff2622F7eA8d38f9'
export const GXD = ENV_PROD
  ? '0xd0b13dEAf962BD61449299457f141Fa8fd6200de'
  : '0x42f9d67db8ca235ba7E1787D998e5F4fC1bA5F3d'
export const CENT = ENV_PROD
  ? '0x912Fe128c052c4279e0Bc19E390bF87F38A38377'
  : '0x998d008b00A1E6cCFB4a4D9A51d7013bda85DA52'
export const DALLION = ENV_PROD
  ? '0x6cba455Cf3EF9eb4e730572296f4Ba8ECCE8745d'
  : '0x956653A39CF59B316c4385079C994f6249F757ff'
export const LBTC = ENV_PROD
  ? '0x1b20E4fbf93117CA04eB019D974702ac3b313551'
  : '0xD4223516bBDF66AF2A893c5376A1B6358aD81bD8'
export const GUSD = ENV_PROD
  ? '0xa9E144413593623A285759E0FF2f74218bACC761'
  : '0xc63f13Ac7da7A5d35e5fB710ed47dE3fD428E124'
export const BGLD = ENV_PROD
  ? '0x67D35FC37a0E39604EA4731D6D802CD50eEe515B'
  : '0xBF9C00A95Cf385d6eE613FE300e1D9248e617c8F'
export const GOAT = ENV_PROD
  ? '0x62e9f230A6286315259b6429D5B28c215bEd9dff'
  : '0x4959489A2a92C26e9BCb91c11fCE394f1cea452A'
export const USDT = ENV_PROD
  ? '0xdef886c55a79830c47108eeb9c37e78a49684e41'
  : '0x233415ca439d1B5D70CB2D1e0811F4DD290A8194'

export const WMATIC = ENV_PROD
  ? '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  : '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889'
export const NATIVE_SYMBOL = ENV_PROD ? 'BNB' : 'MATIC'
export const NATIVE_NAME = ENV_PROD ? 'BNB' : 'Matic Token'
// TODO new TOKEN
