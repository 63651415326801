import { Profile } from 'state/types'
import { API_PROFILE } from 'config/constants/endpoints'

export interface GetProfileResponse {
  hasRegistered: boolean
  profile?: Profile
}

export const getUsername = async (address: string): Promise<string> => {
  try {
    const response = await fetch(`${API_PROFILE}/api/users/${address.toLowerCase()}`)

    if (!response.ok) {
      return ''
    }

    const { username = '' } = await response.json()

    return username
  } catch (error) {
    return ''
  }
}

export const getProfile = async (address: string) => address && null
