import styled from "styled-components";
import React from "react";

const SvgHeaderIcon = styled.svg`
  display: none;
  cursor: pointer;
  @media (max-width: 851px) {
    display: block;
  }
`;

export const HeaderIcon = () => {
  return (
    <SvgHeaderIcon width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 6H20M4 12H20M4 18H20"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgHeaderIcon>
  );
};

export const Polygon = () => {
  return (
    <svg width={20} height={18} fill="none">
      <g clipPath="url(#prefix__clip0_1010_45147)">
        <path
          d="M15.104 5.501c-.364-.214-.833-.214-1.25 0l-2.916 1.763-1.98 1.122-2.864 1.762c-.365.214-.834.214-1.25 0L2.604 8.76a1.309 1.309 0 01-.625-1.122V4.967c0-.427.208-.855.625-1.122l2.24-1.335c.364-.214.833-.214 1.25 0l2.24 1.389c.364.213.624.64.624 1.121v1.763l1.98-1.175V3.792c0-.428-.209-.855-.626-1.122L6.146.16c-.365-.214-.833-.214-1.25 0L.625 2.722C.208 2.937 0 3.364 0 3.792v5.021c0 .427.208.855.625 1.122l4.219 2.51c.364.214.833.214 1.25 0l2.864-1.709 1.98-1.175 2.864-1.71c.365-.213.833-.213 1.25 0l2.24 1.336c.364.214.625.64.625 1.122v2.67c0 .428-.209.855-.625 1.122l-2.188 1.336c-.364.213-.833.213-1.25 0l-2.24-1.336a1.309 1.309 0 01-.624-1.122V11.27l-1.98 1.175v1.763c0 .427.209.855.625 1.122l4.22 2.51c.364.214.832.214 1.25 0l4.218-2.51c.364-.214.625-.641.625-1.122V9.133c0-.427-.208-.854-.625-1.121l-4.219-2.51z"
          fill="#8247E5"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1010_45147">
          <path fill="#fff" transform="translate(0 .053)" d="M0 0h20v17.895H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
