import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg {...props} width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.19667 6.02745L7.5 0.724121L12.8033 6.02745C13.8522 7.07635 14.5665 8.41272 14.8559 9.86758C15.1453 11.3224 14.9968 12.8304 14.4291 14.2009C13.8614 15.5713 12.9001 16.7426 11.6668 17.5668C10.4334 18.3909 8.98336 18.8307 7.5 18.8307C6.01665 18.8307 4.5666 18.3909 3.33324 17.5668C2.09987 16.7426 1.13858 15.5713 0.570916 14.2009C0.00325571 12.8304 -0.145275 11.3224 0.144107 9.86758C0.433489 8.41272 1.14779 7.07635 2.19667 6.02745ZM7.5 3.08079L3.375 7.20579C2.83253 7.7469 2.40234 8.38989 2.10917 9.0978C1.81599 9.80571 1.66561 10.5646 1.66667 11.3308H13.3333C13.3344 10.5646 13.184 9.80571 12.8908 9.0978C12.5977 8.38989 12.1675 7.7469 11.625 7.20579L7.5 3.08079Z" />
    </Svg>
  );
};

export default Icon;
