import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1555_21887)">
        <path d="M60.0509 26.3003L23.5996 60.0515" stroke="#F77C80" stroke-width="3" />
        <circle cx="42.501" cy="42.501" r="25.501" stroke="#F77C80" stroke-width="3" />
      </g>
      <defs>
        <clipPath id="clip0_1555_21887">
          <rect width="85" height="85" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
