import { useTranslation } from "@pancakeswap/localization";
import React from "react";
import styled from "styled-components";
import { StyledFooter } from "./styles";
import { socials } from "./config";
import Link from "next/link";

const StyledLink = styled.a`
  color: #1db8e9;
  font-weight: 700;
`;
const StyledSpan = styled.span`
  font-family: "SF Pro Display", sans-serif;
`;

const StyledSocials = styled.div`
  column-gap: 32px;
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ffffff1a;
  ${({ theme }) => theme.mediaQueries.sm} {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const MenuItem: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledFooter bottom="0" width="100%">
      <StyledSpan>{t("@ 2024 Copyrights by Kujata. All rights reserved.")}</StyledSpan>
      <StyledSocials>
        {socials.map((item, index) => {
          const Icon = item.icon;
          return (
            <StyledLink href={item.href} target="_blank">
              <Icon width="24px" />
            </StyledLink>
          );
        })}
      </StyledSocials>
    </StyledFooter>
  );
};

export default MenuItem;
