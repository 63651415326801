import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { BUSD_ADDRESS as USDC_ADDRESS, CP_ADDRESS as CP_ADDRESS } from '../../env'

export const CAKE_MAINNET = new ERC20Token(
  ChainId.BSC,
  CP_ADDRESS,
  18,
  'TJAY',
  'TJay Games Corporation',
  'https://cookiesprotocol.com/',
)

export const CP = new ERC20Token(ChainId.BSC, CP_ADDRESS, 18, 'CP', 'Cookies Protocol', 'https://cookiesprotocol.com/')

export const CAKE_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new ERC20Token(ChainId.BSC, USDC_ADDRESS, 6, 'USDC', 'USD Coin', 'https://www.centre.io/usdc')

export const USDC_BAHAMUT = new ERC20Token(
  ChainId.BAHAMUT,
  '0x4237e0a5b55233d5b6d6d1d9bf421723954130d8',
  6,
  'USDC',
  'USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)
////////////////////////// USDT Token //////////////////////////
export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059ff775485246999027b3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
)

export const USDT_POL = new ERC20Token(
  ChainId.POLYGON,
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  6,
  'USDT',
  'Tether USD',
)

export const USDT_POL_TEST = new ERC20Token(
  ChainId.POLYGON_TESTNET,
  '0x233415ca439d1B5D70CB2D1e0811F4DD290A8194',
  6,
  'USDT',
  'Tether USD',
)

export const USDT_BSC_TEST = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x29DFDF9EB0770003fa5bC1e3DF36670A4ba63237',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_FUJI = new ERC20Token(
  ChainId.FUJI,
  '0xc58264082e9B15d9992B73e2d1D13B3c50c1b1A6',
  6,
  'USDT',
  'Tether USD',
)

export const USDT_BAHAMUT = new ERC20Token(
  ChainId.BAHAMUT,
  '0xdef886c55a79830c47108eeb9c37e78a49684e41',
  6,
  'USDT',
  'Tether USD',
)

export const FTN_BSC_TEST = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x6A3F85e431a99Ab3fAA3E9EAd358c58F92a09e2F',
  18,
  'FTN',
  'Wrapped Native Token',
)

export const WFTN_BAHAMUT = new ERC20Token(
  ChainId.BAHAMUT,
  '0x4084ab20f8ffca76c19aaf854fb5fe9de6217fbb',
  18,
  'WFTN',
  'Wrapped Native Bahamut Token',
)

////////////////////////// GALX Token //////////////////////////
export const GALX_BSC = new ERC20Token(
  ChainId.BSC,
  '0xf3a1Dd0F9b3C9fBC904A7714506A7BB30180F41A',
  18,
  'GALX',
  'Galux',
  'https://tether.to/',
)

export const GALX_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xB0C8E76d3dfcFA24539960e8C4c968380c915F6c',
  18,
  'GALX',
  'Galux',
)

export const GALX_POL = new ERC20Token(
  ChainId.POLYGON,
  '0xB0C8E76d3dfcFA24539960e8C4c968380c915F6c',
  18,
  'GALX',
  'Galux',
)

export const GALX_POL_TEST = new ERC20Token(
  ChainId.POLYGON_TESTNET,
  '0x195c4AF7EAae1bbD30DF5e4b5C70Dd2128895505',
  18,
  'GALX',
  'Galux',
)

// export const GALX_BSC_TEST = new ERC20Token(
//   ChainId.BSC_TESTNET,
//   '0x464A8466406faDa3a53c78580BBDa6bDC6E270d5',
//   18,
//   'GALX',
//   'Galux',
//   'https://tether.to/',
// )

export const GALX_FUJI = new ERC20Token(ChainId.FUJI, '0x44839db7A66De81a9091C66570EaeF545d4DEE93', 18, 'GALX', 'Galux')

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.POLYGON]: BUSD_BSC,
  [ChainId.POLYGON_TESTNET]: BUSD_BSC,
  [ChainId.FUJI]: BUSD_BSC,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.BAHAMUT]: USDC_BAHAMUT,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.POLYGON]: USDT_POL,
  [ChainId.POLYGON_TESTNET]: USDT_POL_TEST,
  [ChainId.BSC_TESTNET]: USDT_BSC_TEST,
  [ChainId.FUJI]: USDT_FUJI,
  [ChainId.BAHAMUT]: USDT_BAHAMUT,
}

// export const GALX = {
//   [ChainId.BSC]: GALX_BSC,
//   [ChainId.ETHEREUM]: GALX_ETH,
//   [ChainId.POLYGON]: GALX_POL,
//   [ChainId.POLYGON_TESTNET]: GALX_POL_TEST,
//   // [ChainId.BSC_TESTNET]: GALX_BSC_TEST,
//   [ChainId.FUJI]: GALX_FUJI,
// }

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)
